
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "TaskModal",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id") ? VueCookieNext.getCookie("_user_id") : 0,
      component_id: "",
      parent_output_id: "",
      output_id: "",
      activity_id: "",
      title: "",
      selected_ia_partners: [],
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );

    const schema = object().shape({
      component_id: number().required("Component is required"),
      output_id: number().required("Output is required"),
      activity_id: number().required("Activity is required"),
      title: string().required("Activity title is required"),
    });

    const errors = ref({});
    const componentOptions = ref([]);
    const parentOutputOptions = ref([]);
    const outputOptions = ref([]);
    const activityOptions = ref([]);
    const iaPartners = ref([]);

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getIaPrtners = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {}
        );
        console.log(response.data.data);
        iaPartners.value = response.data.data;
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getComponentWiseParentOutput = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_OUTPUT_LIST,
          {
            component_id: formData.value.component_id,
            only_parent: 1,
          }
        );
        parentOutputOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const getParentWiseOutput = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_OUTPUT_LIST,
          {
            parent_id: formData.value.parent_output_id,
          }
        );
        outputOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const getOutputWiseActivity = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_ACTIVITY_LIST,
          {
            output_id: formData.value.output_id,
          }
        );

        activityOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    watch(
      () => formData.value.component_id,
      (newComponent) => {
        if (newComponent !== "") {
          getComponentWiseParentOutput();
        }
      }
    );

    watch(
      () => formData.value.parent_output_id,
      (newParent) => {
        if (newParent !== "") {
          getParentWiseOutput();
        }
      }
    );

    watch(
      () => formData.value.output_id,
      (newParent) => {
        if (newParent !== "") {
          getOutputWiseActivity();
        }
      }
    );

    const save = async () => {
      try {
        if (formData.value && formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }
        // console.log(formData.value.selected_ia_partners);
        await schema.validate(formData.value, { abortEarly: false });
        ApiService.post(
          apiEndpoint.data().VUE_APP_TASK_STORE,
          formData.value
        )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        // formData.value = {
        //   component_id: "",
        //   parent_output_id: "",
        //   output_id: "",
        //   title: "",
        // };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    onMounted(() => {
      getComponent();
      getIaPrtners();
    });

    onMounted(getComponent);
    return {
      formData,
      componentOptions,
      parentOutputOptions,
      outputOptions,
      activityOptions,
      iaPartners,
      errors,
      save,
    };
  },
});
